import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import config from "@/config";
import store from "@/store";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrfnumerique";

Vue.use(VueAxios, axios);

export default (objConfigParam) => {
  const objConfig = objConfigParam || {};

  const instance = Vue.axios.create({
    withCredentials: true,
    baseURL: config.api.baseUrl,
    timeout: objConfig.timeout || 600000,
  });
  instance.interceptors.response.use(
    null,
    (err) => {
      if (err.response && err.response.status && err.response.status === 403) {
        store.commit("setErrorStatus", err.response.status);
      }
      return Promise.reject(err);
    },
  );

  return instance;
};
