import config from "@/config";
import store from "@/store/index";
import i18n from "@/modules/i18n";
import Moment from "moment";

/**
 * Récupère le code de langage du navigateur.
 * @param {Object} options Contient l'option countryCodeOnly si on souhaite seulement le code.
 * @returns {String} Code du langage du navigateur.
 */
export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
}

/**
 * Nous dit si le local en paramètre est supporté par l'application.
 * @param {String} locale Code ISO de la langue.
 * @returns {Boolean} true si locale est supporté par l'application.
 */
export function supportedLocalesInclude(locale) {
  return Object.keys(config.supportedLocales).includes(locale);
}

/**
 * Permet de définir une couleur en fonction d'un taux donné.
 * @param {Number} num Mot ou phrase à transformer.
 * @param {Boolean} inversed Inverse l'ordre des couleurs.
 *                           Par défaut : rouge si >= 100%, ; si inversed : rouge si <= 0%.
 * @returns {String} Classe de la couleur associée.
 */
export function couleurSelonTaux(percent, inversed = false) {
  if (!inversed) {
    if (percent >= 90) {
      if (percent >= 100) {
        return "negative";
      }
      return "warning";
    }
    return "positive";
  }

  if (percent <= 10) {
    if (percent <= 0) {
      return "negative";
    }
    return "warning";
  }
  return "positive";
}
/**
 * Avertit l'utilisateur qu'il n'est pas sur le serveur de production selon son action.
 * @returns {Boolean}
 */
export function confirmOrganismeOnTesting() {
  const isOnProd = [
    "numerique.lde.fr", "cristal-welt.lde.de", "univers-cristal.lde.fr",
  ].includes(window.location.host);

  if (!isOnProd) {
    // eslint-disable-next-line
    const idClient = prompt(i18n.t("general.pas-production-confirmation"), "");
    if (idClient && idClient.toLowerCase() === store.getters.organismeActuel?.id_organisme.toLowerCase()) {
      return true;
    }
    // eslint-disable-next-line no-alert
    alert(i18n.t("general.pas-bon-id-client"));
  } else {
    return true;
  }
  return false;
}

/**
 * Affiche un poids de fichier avec l'unité la plus lisible.
 * @param {Number} bytes Taille en octets.
 */
export function convertSizeFile(bytes) {
  const units = [
    i18n.t("unites.octets"),
    i18n.t("unites.ko"),
    i18n.t("unites.mo"),
    i18n.t("unites.go"),
    i18n.t("unites.to"),
  ];

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return i === 0 ? `${bytes} ${units[i]}` : `${(bytes / (1024 ** i)).toFixed(1)} ${units[i]}`;
}

/**
 * Vérifie si un fichier est correct.
 * @param {Object} file Contient les données du fichier.
 * @param {Object} conf Données de config à valider pour le fichier.
 * @returns {Promise}
 */
export function checkFile(file, conf) {
  const { maxSizeFile, accept, acceptedStr } = conf;

  return new Promise((resolve, reject) => {
    if (file && file.length === 1) {
      const { type, size } = file[0];
      // Vérifier le format
      if (!accept.includes(type)) {
        // Une erreur de type
        const str = `${acceptedStr.slice(0, -1).join(", ")} ou ${acceptedStr.slice(-1)}`;
        reject(new Error(i18n.t("piece-jointe.le-type-du-fichier-nest-pas-correct-il-doit-etre-un-x", [str])));
      } else if (size === 0) {
        // Si pas d'image
        reject(new Error(i18n.t("piece-jointe.image-ne-peut-pas-etre-vide")));
      } else if (convertSizeFile(size) > maxSizeFile) {
        // Si image trop lourde
        reject(new Error(i18n.t("piece-jointe.la-taille-maximal-de-limage-est-de-x-ko", [maxSizeFile])));
      } else {
        resolve(file[0]);
      }
    } else {
      reject(new Error(i18n.t("piece-jointe.plusieurs-fichiers-selectionnes")));
    }
  });
}

/**
 * Change le route.meta.title ainsi que le document.title.
 * @param {String} title Titre à afficher.
 * @param {Object} route Route actuelle du router ($route ne peut pas être importé donc on le passe en param).
 */
export function changePageTitle(title, route) {
  if (route.name === "home" || !title) {
    document.title = `${i18n.t("general.cristal-commandes")} - LDE`;
  } else {
    document.title = `${title} - ${i18n.t("general.cristal-commandes")} - LDE`;
  }

  if (route) {
    route.meta.title = title;
  }
}

/**
 * Vérifie si on a le droit d'accéder aux désiderata (livraison).
 * @param {Object} maitreSite Maître site du compte courant. Le compte courant peut être le maître site.
 */
export function canAccessDesiderata(maitreSite) {
  if (maitreSite) {
    const year = Moment().year();
    return Moment().isBetween(`${year}-06-03`, `${year}-11-01`, "days", "[]")
      && store.getters.hasPerm("can_set_dates_livraison")
      && store.getters.isHorsMarche
      && !maitreSite.marches?.length
      && !maitreSite.infos.exclus_du_1500
      && !maitreSite.infos?.categorie?.toLowerCase().includes("primaire")
      && maitreSite?.pays_livraison?.toLowerCase() === "france"
      && maitreSite?.pays_facturation?.toLowerCase() === "france";
  }
  return false;
}

/**
 * Permet d'accéder dynamiquement à une propriété en profondeur dans un objet.
 * @param {Object} obj Objet sur lequel on veut récuprer une valeur.
 * @param {String} keys Clés imbriquées de l'objet.
 * @returns {?} Valeur associée à la clé (peut être de tous les types).
 */
export function getNestedProperty(obj, keys) {
  let result = obj;
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (result === undefined) {
      // Gérer le cas où la propriété n'existe pas
      return undefined;
    }
    result = result[key];
  }
  return result;
}

/**
 * Trie les données du tableau selon la valeur choisie.
 * @param {Object} sortAscended Si true, le tri est croissant.
 * @param {Object} key Clé sur laquelle trier.
 */
export function handleSort({ sortAscended, key }) {
  this.$router.push({
    query: {
      ...this.$route.query,
      sort: `${sortAscended ? "" : "-"}${key}`,
      page: 1,
    },
  });
}

/**
 * Créer une promesse différée.
 * @returns {Object} Promesse différée.
 */
export function createDeferredPromise() {
  let resolve;
  let reject;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { promise, resolve, reject };
}
