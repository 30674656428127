<template>
  <Modal
    id="modal_listes_saisie_rapide"
    @change="!$event && hide()"
  >
    <template #title>
      {{ $t('liste.creer-nouvelle-liste') }}
    </template>
    <template #subtitle>
      {{ listName }}
    </template>
    <template #content>
      <table class="text-medium">
        <thead>
          <tr>
            <th
              v-for="item in tableHeader"
              :key="item.key"
            >
              <component
                :is="item.key === 'ean_ref' ? 'div' : 'button'"
                class="button filter-button"
                @click="sortOffers(item.key)"
              >
                <UisSorting
                  v-if="item.key !== 'ean_ref'"
                  size="16"
                />
                <span class="text-small">{{ item.name }}</span>
              </component>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="ligne, index in lignes">
            <tr :key="index">
              <td>
                <SelectClassic
                  v-model="ligne.offre"
                  :label="$t('produit.ean-ref')"
                  status="required"
                  :options="options"
                  inline
                  :internal-search="false"
                  :options-are-loading="optionsAreLoading"
                  @search="fetchOffres($event)"
                  @select="handleSelect(ligne, index)"
                />
              </td>
              <td>
                <InputClassic
                  v-model="ligne.quantite"
                  :label="$t('general.quantite')"
                  status="required"
                  type="number"
                  min="1"
                  inline
                />
              </td>
              <td>{{ ligne.offre?.libelle || "-" }}</td>
              <td>
                <template v-if="ligne.offre?.prix_public">
                  {{ ligne.offre.prix_public | moneyFilter }}{{ "\xa0" }}€
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td>
                <span
                  class="circle"
                  :class="ligne.offre?.dispo"
                />
              </td>
              <td>
                <ButtonClassic
                  variant="solid"
                  color="primary"
                  icon="left"
                  size="small"
                  @click="deleteLine(index)"
                >
                  <template #left-icon>
                    <UilTrashAlt />
                  </template>
                </ButtonClassic>
              </td>
            </tr>
            <!-- Si le produit n'est plus disponible et a été remplacé -->
            <tr
              v-if="ligne.offre?.eanBase"
              :key="`additional_${index}`"
            >
              <td :colspan="tableHeader.length">
                {{ $t("produit.ean-x-plus-disponible-remplace-par-nouvelle-version", { ean: ligne.offre?.eanBase, }) }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <ButtonClassic
        variant="ghost"
        color="primary"
        :label="$t('liste.nouvelle-ligne')"
        icon="left"
        @click="lignes = [...lignes, {}, ]"
      >
        <template #left-icon>
          <UilPlusCircle />
        </template>
      </ButtonClassic>
      <ButtonClassic
        variant="ghost"
        color="primary"
        :label="$t('liste.ajouter-des-lignes')"
        icon="left"
        @click="lignes = [...lignes, {}, {}, {}, {}, {}, ]"
      >
        <template #left-icon>
          <UilPlusCircle />
        </template>
      </ButtonClassic>
    </template>
    <template #footer>
      <button
        class="button text-medium underline"
        @click="hide()"
      >
        {{ $t("action.annuler") }}
      </button>
      <ButtonClassic
        variant="solid"
        color="secondary"
        :label="$t('liste.creer-liste')"
        :disabled="isImpersonating || isCreatingList"
        icon="left"
        @click="createList()"
      >
        <template #left-icon>
          <UilPlusCircle />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic,
  SelectClassic,
  InputClassic,
  Modal,
} from "@lde/core_lde_vue";

import { UilTrashAlt, UilPlusCircle } from "@iconscout/vue-unicons";
import { UisSorting } from "@iconscout/vue-unicons-solid";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";
import mixinToListe from "@/mixins/mixinToListe";

import Api from "@/modules/axios";

/**
 * Modale affichée lors de la création d'une liste à partir de lignes de liste.
 */
export default {
  name: "ModalListesSaisieRapide",
  components: {
    Modal,
    ButtonClassic,
    InputClassic,
    SelectClassic,
    UilTrashAlt,
    UilPlusCircle,
    UisSorting,
  },
  mixins: [mixinDisponibilite, mixinToListe],
  props: {
    /**
     * Nom de la liste.
     */
    listName: {
      type: String,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché à l'ajout de la liste.
     * @param {Object} - La nouvelle liste créée.
     */
    "submit",
  ],
  data() {
    return {
      tableHeader: [
        { key: "ean_ref", name: this.$t("produit.ean-ref") },
        { key: "quantite", name: this.$t("general.quantite") },
        { key: "libelle", name: this.$t("general.designation") },
        { key: "prix_public", name: this.$tc("prix.prix-public") },
        { key: "dispo", name: this.$tc("general.dispo") },
      ],
      lastSort: {},
      lignes: [{}, {}, {}, {}],
      options: [],
      optionsAreLoading: false,
      isCreatingList: false,
    };
  },
  methods: {
    /**
     * Remet les valeurs par défaut une fois la modale fermée.
     */
    hide() {
      this.$modal.hide("modal_listes_saisie_rapide");
      this.lignes = [{}, {}, {}, {}];
    },
    /**
     * Trie les éléments du tableau.
     * @param {String} sortKey Nom du champ sur lequel trier les offres.
     */
    sortOffers(sortKey) {
      const filledLines = [];
      const emptyLines = [];

      this.lignes.forEach((ligne) => {
        if (Object.keys(ligne).includes(sortKey)) {
          filledLines.push(ligne);
        } else {
          emptyLines.push(ligne);
        }
      });

      if (this.lastSort.key !== sortKey) {
        // Si le dernier tri n'est pas sur la même colonne, on trie par défaut
        filledLines.sort((a, b) => {
          if (a.offre && b.offre) {
            if (a.offre[sortKey] < b.offre[sortKey]) return -1;
            if (a.offre[sortKey] > b.offre[sortKey]) return 1;
          }
          return 0;
        });
        this.lastSort.asc = true;
      } else {
        // Si le dernier tri est sur la même colonne, on effectue l'inverse du précédent tri
        filledLines.reverse();
        this.lastSort.asc = !this.lastSort.asc;
      }
      this.lastSort.key = sortKey;
      this.lignes = [...filledLines, ...emptyLines];
    },
    /**
     * Récupère les offres avec ses informations selon le texte tapé.
     * @param {String} searchText Texte tapé dans l'input.
     * @param {String} ligne Ligne sur laquelle on recherche une offre.
     */
    fetchOffres(searchText, ligne) {
      this.optionsAreLoading = true;
      const searchtextFormatted = searchText.replaceAll("-", "").replaceAll(" ", "");

      Api().get(`/recherche/offre/?search=${searchtextFormatted}`)
        .then(({ data }) => {
          this.options = data.results.map((res) => ({
            label: `${res.ean}_${res.ref}`,
            value: `${res.ean}_${res.ref}`,
            id: res.id,
            ean: res.ean,
            libelle: res.produit.libelle,
            prix_public: res.prix_public,
            dispo: this.couleurSelonDisponibilite(res.produit),
            produit_remplacement: res.produit.produit_remplacement,
          }));
          this.optionsAreLoading = false;
          if (this.options.length === 1 && this.options[0].label.length === searchtextFormatted.length) {
            const index = this.lignes.indexOf(ligne);
            this.lignes[index].ean = this.options[0];
            this.handleSelect(ligne);
          }
        });
    },
    /**
     * Permet de définir le comportement de la ligne lorsqu'un produit est sélectionné.
     * @param {Object} selectedLine Ligne sélectionnée.
     * @param {Number} index Index de la ligne sélectionnée.
     */
    handleSelect(selectedLine, index) {
      // Remplace le produit indisponible par sa dernière version si elle est disponible
      const oldEan = { ...selectedLine }.offre.ean;
      const produitRemplacement = selectedLine.offre.produit_remplacement;

      if (selectedLine.offre?.dispo === "red" && this.couleurSelonDisponibilite(produitRemplacement) !== "red") {
        this.lignes[index].offre = {
          label: `${produitRemplacement.ean}_${produitRemplacement.ref_editeur}`,
          value: `${produitRemplacement.ean}_${produitRemplacement.ref_editeur}`,
          id: produitRemplacement.id,
          ean: produitRemplacement.ean,
          libelle: produitRemplacement.libelle,
          prix_public: produitRemplacement.offre_defaut.prix_editeur,
          dispo: this.couleurSelonDisponibilite(produitRemplacement),
          eanBase: oldEan,
        };
      }

      // Met la quantité à 1 par défaut
      if (!selectedLine.quantite) {
        this.$set(selectedLine, "quantite", 1);
      }
    },
    /**
     * Supprime ou vide une ligne du tableau.
     * @param {Number} index Index de la ligne à supprimer ou à vider.
     */
    deleteLine(index) {
      if (this.lignes.length > 4) {
        this.lignes.splice(index, 1);
      } else {
        this.$set(this.lignes, index, {});
      }
    },
    /**
     * Créer une nouvelle liste avec les lignes de la modale.
     */
    createList() {
      this.isCreatingList = true;
      if (this.lignes.some((ligne) => ligne?.offre?.dispo === "red")) {
        this.$toast.error({ title: this.$t("produit.impossible-ajouter-produits-indispo") });
        this.isCreatingList = false;
      } else {
        const linesToAdd = this.lignes.filter((ligne) => ligne.offre).map((line) => ({
          id_offre: line?.offre.id,
          quantite: parseInt(line.quantite, 10),
        }));

        // Supprime les doublons du tableau d'objets et additionne les quantités en cas de doublons
        const linesToAddDistinct = linesToAdd.reduce((acc, item) => {
          const existingItem = acc.find((other) => other.id_offre === item.id_offre);
          if (existingItem) {
            existingItem.quantite += item.quantite;
          } else {
            acc.push(item);
          }
          return acc;
        }, []);

        Api().post("/liste/", { libelle: this.listName })
          .then(async ({ data }) => {
            this.addOffersToList({ liste: data, lignes: linesToAddDistinct });
            this.$toast.success({ title: this.$t("liste.liste-x-bien-creee", { libelle: data.libelle }) });
            this.hide();
          })
          .catch((err) => {
            this.$toast.error({ title: err.response?.data || this.$t("info.une-erreur-est-survenue") });
          })
          .finally(() => {
            this.isCreatingList = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_listes_saisie_rapide.scss";
</style>
