<template>
  <div id="app">
    <!-- Affiche un loader le temps que la connexion se fasse (très rapide) -->
    <div
      v-if="(!isLogged && !$route.meta.accessNotLogged) || $route.name === null"
      id="app_loader"
    >
      <InfiniteLoader
        :height="64"
        :width="64"
      />
    </div>
    <div
      v-else-if="$route.meta.accessNotLogged && !isLogged"
      id="app_content_not_logged"
    >
      <router-view />
    </div>
    <div
      v-else
      id="app_content"
    >
      <div id="sidebar">
        <section
          id="menu"
          :class="{ 'dark-mode': darkMode, }"
        >
          <NavigationMenu
            :expanded-menu="expandedSidebar"
            :dark-mode="darkMode"
            :menu="forbiddenMode || askChooseOrg ? [] : menuToShow"
          />
          <ButtonClassic
            id="dark_mode"
            :class="{ reduce: !expandedSidebar, }"
            :label="labelDarkMode"
            variant="special"
            :color="darkMode ? 'white' : 'primary'"
            icon="right"
            size="small"
            @mouseover.native="hoveredDarkMode = true"
            @mouseleave.native="hoveredDarkMode = false"
            @click="darkMode = !darkMode"
          >
            <template #right-icon>
              <UilSun v-if="darkMode" />
              <UilMoon v-else />
            </template>
          </ButtonClassic>
        </section>
        <footer id="assistance">
          <ButtonClassic
            v-if="!isGE"
            v-matomo-log-click="['assistance_footer', ]"
            v-tooltip="{
              content: $tc('general.mail-copie-info', 0, { mail: mailLde, } ),
              placement: 'top',
              disabled: !isDe,
              delay: { show: 800, },
            }"
            v-bind="btnAssistanceAttr"
            variant="special"
            color="white"
            icon="left"
            @click="copyMailLde()"
          >
            <template #left-icon>
              <UilQuestionCircle />
            </template>
          </ButtonClassic>
        </footer>
      </div>
      <div id="wrapper">
        <div
          v-if="impersonator"
          id="impersonating"
          class="text-small"
        >
          {{ $t("general.vous-etes-connectes-en-tant-que-x", {
            nom: `${user.contact.prenom } ${user.contact.nom}`, } ) }}
          <a :href="impersonateStopUrl">
            {{ $t("general.revenir-a-x", { nom: `${impersonator.contact.prenom } ${impersonator.contact.nom}`, } ) }}
          </a>
        </div>
        <Header
          :dark-mode="darkMode"
          :disabled="forbiddenMode || askChooseOrg"
          :expanded-sidebar="internalExpandedSidebar"
          @change-expanded-sidebar="internalExpandedSidebar = $event"
        />
        <main
          id="scroll_content"
          data-bs-spy="scroll"
          data-bs-target="#nav_titles"
          tabindex="0"
          @scroll="toggleHeaderScroll"
        >
          <AccessProvider v-if="askLinkAccount" />
          <MultipleAccounts v-else-if="askChooseOrg" />
          <ErrorPage
            v-else-if="goToErrorPage"
            :variant="errorVariant"
          />
          <template v-else>
            <router-view :key="$route.path" />
            <ModalVerifyAccount />
          </template>
          <span id="custom_radius" />
          <MainFooter>
            <template #left-links>
              <a
                :href="config.url.mentionsLegales"
                target="_blank"
                rel="noreferrer noopener"
              >
                {{ $t('footer.mentions-legales') }}
              </a>
              <a
                :href="config.url.cgu"
                target="_blank"
                rel="noreferrer noopener"
              >
                {{ $t('footer.cgu') }}
              </a>
              <a
                :href="config.url.rgpd"
                target="_blank"
                rel="noreferrer noopener"
              >
                {{ $t('footer.rgpd') }}
              </a>
              <button
                class="button"
                @click="showCookies = true"
              >
                {{ $t('footer.cookies') }}
              </button>
            </template>
          </MainFooter>
        </main>
      </div>
      <transition name="slide">
        <CookiesControl
          v-if="!user.cookies || !user.cookies.date || showCookies"
          @save="setCookiesFeatures($event)"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import {
  ButtonClassic,
  InfiniteLoader,
  MainFooter,
  NavigationMenu,
} from "@lde/core_lde_vue";

import ErrorPage from "@/components/layout/ErrorPage.vue";
import AccessProvider from "@/components/layout/AccessProvider.vue";
import Header from "@/components/layout/Header.vue";
import CookiesControl from "@/components/CookiesControl.vue";
import MultipleAccounts from "@/components/layout/MultipleAccounts.vue";
import ModalVerifyAccount from "@/components/modals/ModalVerifyAccount.vue";

import config from "@/config";

import { mapGetters } from "vuex";

import Sentry from "@/modules/sentry";
import { changePageTitle } from "@/modules/utils";

import menu from "@/router/menu";

import { UilQuestionCircle, UilMoon, UilSun } from "@iconscout/vue-unicons";

/**
 * Définit la structure de base du template du site.
 */
export default {
  name: "App",
  components: {
    Header,
    NavigationMenu,
    ButtonClassic,
    MainFooter,
    ErrorPage,
    AccessProvider,
    InfiniteLoader,
    CookiesControl,
    MultipleAccounts,
    ModalVerifyAccount,
    UilQuestionCircle,
    UilMoon,
    UilSun,
  },
  data() {
    return {
      menu,
      showCookies: false,
      internalExpandedSidebar: true,
      hoveredDarkMode: false,
      authorizedRoutes: ["mentions_legales", "cgu", "rgpd"],
      isLoading: false,
    };
  },
  computed: {
    config() {
      return config;
    },
    ...mapGetters([
      "isLogged",
      "askLinkAccount",
      "errorStatus",
      "accessForbidden",
      "preferences",
      "user",
      "hasPerms",
      "hasPerm",
      "country",
      "isGE",
      "impersonator",
      "askChooseOrg",
      "urlHotline",
      "urlCristalWeb",
    ]),
    isDe() {
      return this.country === "de";
    },
    expandedSidebar() {
      return this.forbiddenMode || this.askChooseOrg ? false : this.internalExpandedSidebar;
    },
    forbiddenMode() {
      return this.accessForbidden || this.askLinkAccount;
    },
    darkMode: {
      get() {
        return !!this.preferences?.darkMode;
      },
      set(val) {
        this.$store.commit("updatePreferences", { darkMode: val });
      },
    },
    labelDarkMode() {
      if (this.hoveredDarkMode && this.expandedSidebar) {
        return this.darkMode ? this.$t("general.mode-jour") : this.$t("general.mode-nuit");
      }
      return null;
    },
    goToErrorPage() {
      return !this.authorizedRoutes.includes(this.$route.name) && (this.forbiddenMode || this.errorStatus);
    },
    errorVariant() {
      if (this.$route.name === "404" || this.errorStatus === 404) {
        return "404";
      }
      if (this.errorStatus === 403) {
        return "403";
      }
      return "site-forbidden";
    },
    btnAssistanceAttr() {
      const attr = {};
      if (this.expandedSidebar) {
        attr.label = this.$t("general.assistance");
      }
      if (!this.isDe) {
        attr.baliseType = "a";
        attr.extLink = this.urlHotline;
      }
      return attr;
    },
    mailLde() {
      return config.mails.lde;
    },
    isFactureVisible() {
      return this.hasPerm("view_facture");
    },
    menuToShow() {
      if (!this.isLogged) {
        return [];
      }

      return this.menu
        .filter((m) => this.filterMenu(m))
        .map((m) => ({
          ...m,
          title: this.getTitle(m),
          submenu: m.submenu
            .filter((sm) => this.filterMenu(sm))
            .map((sm) => ({
              ...sm,
              // Définit le lien vers le serveur CW
              extLink: sm.name === "cristal_web" && this.hasPerm("use_cristalweb") ? this.urlCristalWeb : undefined,
            })),
        }));
    },
    impersonateStopUrl() {
      return `${config.backUrl}/impersonate/stop`;
    },
  },
  watch: {
    $route: {
      // Réinitialise l'erreur pour permettre à nouveau la navigation
      handler(route, oldRoute) {
        if (route.path !== oldRoute.path || JSON.stringify(route.query) !== JSON.stringify(oldRoute.query)) {
          this.$store.commit("setErrorStatus", null);
        }
      },
    },
    "$route.meta.title": {
      handler(title) {
        changePageTitle(title, this.$route);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    customCopyToClipboard(text, title = null) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.success({ title: title || this.$t("toast.element-copie-presse-papier") });
    },
    /**
     * Copie l'adresse mail de l'assistance LDE.
     * Utilisé pour l'Allemagne.
     */
    copyMailLde() {
      if (this.isDe) {
        this.customCopyToClipboard(
          this.mailLde, this.$tc("general.email-copiee", 0, { mail: this.mailLde }),
        );
      }
    },
    /**
     * Définit les fonctionnalités à activer ou non selon les cookies et ferme le bandeau avec une animation.
     * @param {Object} cookies Choix de l'utilisateur sur les cookies.
     */
    setCookiesFeatures(cookies) {
      if (cookies.technique) {
        Sentry.init();
        Sentry.setScope();
      } else {
        Sentry.unsetScope();
        Sentry.flush();
      }

      window._paq.push([cookies.audience ? "setConsentGiven" : "forgetConsentGiven"]);
      this.showCookies = false;
    },
    /**
     * Affiche le header si on scroll vers le bas. Le cache si on scroll vers le haut.
     * Adapte aussi la hauteur du #aside_content.
     * @param {Object} event Événement natif du scroll.
     */
    toggleHeaderScroll(event) {
      const currentScrollTop = event.target.scrollTop;
      const pageHeader = document.querySelector("#page_header");

      if (pageHeader) {
        // Décale le header de sa propre taille (la hauteur peut varier selon le contenu) quand on scrolle vers le bas
        // Reset la position du header si on scrolle vers le haut
        pageHeader.style.top = currentScrollTop >= this.prevScrollPos ? `-${pageHeader.offsetHeight}px` : 0;
        this.prevScrollPos = currentScrollTop;
      }
    },
    /**
     * Change le titre du menu selon la permission.
     * @param {Object} link Menu courant.
     */
    getTitle({ name, title }) {
      // Si on peut voir les factures, on change le titre du menu.
      if (name === "commandes_factures" && this.isFactureVisible) {
        return this.$t("recherche.commandes-et-factures");
      }

      // Si on est maître compta, on met au pluriel le titre des établisements
      if (name === "etablissements" && this.hasPerm("can_view_as_maitre_compta")) {
        return this.$tc("menu.mes-etablissements", 2);
      }

      return title;
    },
    /**
     * Filtre le menu.
     * @param {Object}
     */
    filterMenu({ permissions, name }) {
      if (name === "factures") {
        return this.isFactureVisible;
      }
      return this.hasPerms(permissions);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/app.scss";
</style>
